export const ServiceTypes = {
    AutoMapper: Symbol('AutoMapper'),

    UserManager: Symbol('UserManager'),
    UserManagerSettings: Symbol('UserManagerSettings'),

    BeBonusService: Symbol('BeBonusService'),
    BrandService: Symbol('BrandService'),

    ApolloClientBuilderGraphQlWs: Symbol('ApolloClientBuilderGraphQlWs'),
    ApolloClientIGP: Symbol('ApolloClientIGP'),
    ApolloClientIGPMocked: Symbol('ApolloClientIGPMocked'),
    ApolloClientPE: Symbol('ApolloClientPE'),
    ApolloClientInMemoryCache: Symbol('ApolloClientInMemoryCache'),

    RestService: Symbol('RestService'),
    RestFetchApiService: Symbol('RestFetchApiService'),
    TracingService: Symbol('TracingService'),
    RealtimeService: Symbol('RealtimeService'),
    ApiRoutesService: Symbol('ApiRoutesService'),
    JurisdictionConfigService: Symbol('JurisdictionConfigService'),

    ManualTransactionExcelTemplateFactory: Symbol('ManualTransactionExcelTemplateFactory'),
    EntityReadServiceFactory: Symbol('EntityReadServiceFactory'),
    AgentReportingCsvFileFactory: Symbol('AgentReportingCsvFileFactory'),

    CsvFileAdapter: Symbol('CsvFileAdapter'),
    FileDownloadService: Symbol('FileDownloadService'),

    // config
    Config: Symbol('Config'),

    // entity services
    AccountVerificationService: Symbol('AccountVerificationService'),
    AgentPathStatisticsService: Symbol('AgentPathStatisticsService'),
    BoModuleService: Symbol('BoModuleService'),
    BoRoleService: Symbol('BoRoleService'),
    BoUserService: Symbol('BoUserService'),
    BotGroupService: Symbol('BotGroupService'),
    BulkOperationService: Symbol('BulkOperationService'),
    CountryService: Symbol('CountryService'),
    FriendGroupService: Symbol('FriendGroupService'),
    KycCheckResultsService: Symbol('KycCheckResultsService'),
    LegalDocAcceptanceVersionService: Symbol('LegalDocAcceptanceVersionService'),
    MarketingCodeService: Symbol('MarketingCodeService'),
    NoteService: Symbol('NoteService'),
    OccupationService: Symbol('OccupationService'),
    PlayerBonusService: Symbol('PlayerBonusService'),
    PlayerReferralService: Symbol('PlayerReferralService'),
    PlayerReferrerSummaryService: Symbol('PlayerReferrerSummaryService'),
    PlayersRelationService: Symbol('PlayersRelationService'),
    ReferralSettingsService: Symbol('ReferralSettingsService'),
    RgUserLimitsService: Symbol('RgUserLimitsService'),
    SecurityCaseService: Symbol('SecurityCaseService'),
    StateService: Symbol('StateService'),
    TransactionService: Symbol('TransactionService'),
    UserActionService: Symbol('UserActionService'),
    UserLoginService: Symbol('UserLoginService'),
    UserProfileService: Symbol('UserProfileService'),
    LabelGroupService: Symbol('LabelGroupService'),
    UserStatisticsService: Symbol('UserStatisticsService'),
    EventLogRecordService: Symbol('EventLogRecordService'),
    GeoCheckSummaryService: Symbol('GeoCheckSummaryService'),
    AggregatedFinanceSummaryService: Symbol('AggregatedFinanceSummaryService'),
    AgentPlayerReportingService: Symbol('AgentPlayerReportingService'),
    AgentProfileService: Symbol('AgentProfileService'),
    BackpackService: Symbol('BackpackService'),
    GameTemplateService: Symbol('GameTemplateService'),
    GameBlindService: Symbol('GameBlindService'),
    GameRoomService: Symbol('GameRoomService'),
    BonusCodeService: Symbol('BonusCodeService'),
    RuleService: Symbol('RuleService'),
    ReportMarketingService: Symbol('ReportMarketingService'),
    CasinoCmsApiService: Symbol('CasinoCmsApiService'),
    AttachmentsApiService: Symbol('AttachmentsApiService'),
    CasinoSupplierService: Symbol('CasinoSupplierService'),
    CasinoGameService: Symbol('CasinoGameService'),
    UserManagementApiService: Symbol('UserManagementApiService'),
    AgentLevelReportService: Symbol('AgentLevelReportService'),
    SportsbookTransactionService: Symbol('SportsbookTransactionService'),
    EmbeddedReportService: Symbol('EmbeddedReportService'),
    PageViewService: Symbol('PageViewService'),
    UserActivitySummaryService: Symbol('UserActivitySummaryService'),

    // api services
    BoUserApiService: Symbol('BoUserApiService'),
    CountriesApiService: Symbol('CountriesApiService'),
    OccupationsApiService: Symbol('OccupationsApiService'),
    PaymentApiService: Symbol('PaymentApiService'),
    PlayerManagementApiService: Symbol('PlayerManagementApiService'),
    GameManagementApiServiceFactory: Symbol('GameManagementApiServiceFactory'),
    Plo5GameManagementApiService: Symbol('Plo5GameManagementApiService'),
    RuleApiService: Symbol('RuleApiService'),
    SportsbookApiService: Symbol('SportsbookApiService'),

    LoadManualTransactionsStrategy: Symbol('LoadManualTransactionsStrategy'),
    LoadPlayerItemsStrategy: Symbol('LoadPlayerItemsStrategy'),
    LoadP2PTransferStrategy: Symbol('LoadP2PTransferStrategy'),
    ValidationManualTransactionsStrategy: Symbol('ValidationManualTransactionsStrategy'),
    ValidationP2PTransferStrategy: Symbol('ValidationP2PTransferStrategy'),
    ApplyManualTransactionsStrategy: Symbol('ApplyManualTransactionsStrategy'),
    ApplyP2PTransfersStrategy: Symbol('ApplyP2PTransfersStrategy'),
    PerformManualTransactionsStrategy: Symbol('PerformManualTransactionsStrategy'),
    ApplyTriggerMttBonusStrategy: Symbol('ApplyTriggerMttBonusStrategy'),
    ApplyTriggerImmediateCashBonusStrategy: Symbol('ApplyTriggerImmediateCashBonusStrategy'),
    ApplyTriggerDepositCashMatchBonusStrategy: Symbol('ApplyTriggerDepositCashMatchBonusStrategy'),
    PerformTriggerMttBonusStrategy: Symbol('PerformTriggerMttBonusStrategy'),
    PerformTriggerImmediateCashBonusStrategy: Symbol('PerformTriggerImmediateCashBonusStrategy'),
    PerformTriggerDepositCashMatchBonusStrategy: Symbol('PerformTriggerDepositCashMatchBonusStrategy'),
    PerformP2PLockStrategy: Symbol('PerformP2PLockStrategy'),
    ApplySecurityCasesAddStrategy: Symbol('ApplySecurityCasesAddStrategy'),
    ApplySecurityCasesUpdateStrategy: Symbol('ApplySecurityCasesUpdateStrategy'),
    ApplySecurityCasesRemoveStrategy: Symbol('ApplySecurityCasesRemoveStrategy'),
    PerformSecurityCasesStrategy: Symbol('PerformSecurityCasesStrategy'),
    ApplySendMessageStrategy: Symbol('ApplySendMessageStrategy'),
    ApplyNoteStrategy: Symbol('ApplyNoteStrategy'),
    PerformSendMessageStrategy: Symbol('PerformSendMessageStrategy'),
    PerformNoteStrategy: Symbol('PerformNoteStrategy'),
    ApplyAddUserLabelStrategy: Symbol('ApplyAddUserLabelStrategy'),
    ApplyRemoveUserLabelStrategy: Symbol('ApplyRemoveUserLabelStrategy'),
    PerformAddUserLabelStrategy: Symbol('PerformAddUserLabelStrategy'),
    PerformRemoveUserLabelStrategy: Symbol('PerformRemoveUserLabelStrategy'),
    ApplyAccountLockStrategy: Symbol('ApplyAccountLockStrategy'),
    ApplyWithdrawalLockStrategy: Symbol('ApplyWithdrawalLockStrategy'),
    ApplyDepositLockStrategy: Symbol('ApplyDepositLockStrategy'),
    ApplyLobbyLockStrategy: Symbol('ApplyLobbyLockStrategy'),
    ApplyCasinoLockStrategy: Symbol('ApplyCasinoLockStrategy'),
    ApplySportsbookLockStrategy: Symbol('ApplySportsbookLockStrategy'),
    ApplyP2PLockStrategy: Symbol('ApplyP2PLockStrategy'),
    PerformWithdrawalLockStrategy: Symbol('PerformWithdrawalLockStrategy'),
    PerformDepositLockStrategy: Symbol('PerformDepositLockStrategy'),
    PerformLobbyLockStrategy: Symbol('PerformLobbyLockStrategy'),
    PerformCasinoLockStrategy: Symbol('PerformCasinoLockStrategy'),
    PerformSportsbookLockStrategy: Symbol('PerformSportsbookLockStrategy'),
    PerformAccountLockStrategy: Symbol('PerformAccountLockStrategy'),
    PerformP2PTransfersStrategy: Symbol('PerformP2PTransfersStrategy'),
    BonusCodeDownloadStrategy: Symbol('BonusCodeDownloadStrategy'),
    ApplyKYCAssignStrategy: Symbol('ApplyKYCAssignStrategy'),
    ApplyTransactionAssignStrategy: Symbol('ApplyTransactionAssignStrategy'),
    PerformKYCAssignStrategy: Symbol('PerformKYCAssignStrategy'),
    PerformTransactionAssignStrategy: Symbol('PerformTransactionAssignStrategy'),

    ActionBulkTransactionEpicsBuilder: Symbol('ActionBulkTransactionEpicsBuilder'),
    ActionBulkEpicsBuilder: Symbol('ActionBulkEpicsBuilder'),
    BlockUserLabelEpicsBuilder: Symbol('BlockUserLabelEpicsBuilder'),
    AccountVerificationActionsEpicsBuilder: Symbol('AccountVerificationActionsEpicsBuilder'),
    TransactionActionsEpicsBuilder: Symbol('TransactionActionsEpicsBuilder'),
    UserProfilePersonalInfoEpicsBuilder: Symbol('UserProfilePersonalInfoEpicsBuilder'),
    UserAddressInfoEpicsBuilder: Symbol('UserAddressInfoEpicsBuilder'),
    ReferralSettingsEpicsBuilder: Symbol('ReferralSettingsEpicsBuilder'),
    EntityEpicsBuilder: Symbol('EntityEpicsBuilder'),
    RealtimeEpicsBuilder: Symbol('RealtimeEpicsBuilder'),
    AsyncActionsEpicsBuilder: Symbol('AsyncActionsEpicsBuilder'),
    RoutingEpicsBuilder: Symbol('RoutingEpicsBuilder'),
    UserLoginActionsEpicsBuilder: Symbol('UserLoginActionsEpicsBuilder'),
    PlayerReferralEpicsBuilder: Symbol('PlayerReferralEpicsBuilder'),
    UserProfileActionsEpicsBuilder: Symbol('UserProfileActionsEpicsBuilder'),
    AgentReportingActionsEpicsBuilder: Symbol('AgentReportingActionsEpicsBuilder'),
    GameTemplateActionsEpicsBuilder: Symbol('GameTemplateActionsEpicsBuilder'),
    GameRoomActionsEpicsBuilder: Symbol('GameRoomActionsEpicsBuilder'),
    BonusCodeEpicsBuilder: Symbol('BonusCodeEpicsBuilder'),
    NoteActionsEpicsBuilder: Symbol('NoteActionsEpicsBuilder'),
    RuleActionsEpicsBuilder: Symbol('RuleActionsEpicsBuilder'),
    PlayerBonusEpicsBuilder: Symbol('PlayerBonusEpicsBuilder'),
    AgentLevelReportEpicsBuilder: Symbol('AgentLevelReportEpicsBuilder'),
    EmbeddedReportEpicsBuilder: Symbol('EmbeddedReportEpicsBuilder'),
    PageViewEpicsBuilder: Symbol('PageViewEpicsBuilder'),
    FileEpicsBuilder: Symbol('FileEpicsBuilder'),
    DownloadEpicsBuilder: Symbol('FileEpicsBuilder'),

    //TODO: Remove ServiceContainer once not used
    /**
     * @deprecated
     * <p>Temporary configuration for old services</p>
     */
    ServiceContainer: Symbol('ServiceContainer'),
} as const;

export type ServiceTypesKeys = keyof typeof ServiceTypes;
