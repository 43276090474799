import React from 'react';
import {Controller} from 'react-hook-form';
import {defineMessages} from 'react-intl';
import {Box} from '@mui/material';

import {MultiInputEditableText} from '@components/editable/MultiInputEditableText';
import {FormError} from '@components/input';
import {ModuleName, SubmoduleName} from '@models/modules';
import {PermissionEnum} from '@models/permissions';

import {useMessageFormatter} from '../../app/intl/hooks';
import {useMultiplePermissions, useRolesPermission} from '../../app/permission/PermissionHoc';
import {UserProfileModel} from '../../player-actions/pii-actions/types';
import {useReduxForm} from '../../shared/form/hooks';
import {personalInfoActions} from '../actions';

const localized = defineMessages({
    piiPhoneNumberFieldName: {
        id: 'piiPhoneNumberFieldName',
        defaultMessage: 'Phone Number',
    },
    piiPhoneAreaFieldName: {
        id: 'piiPhoneAreaFieldName',
        defaultMessage: 'Area',
    },
    piiPhoneAreaPlaceholder: {
        id: 'piiPhoneAreaPlaceholder',
        defaultMessage: '+123',
    },
    piiPhonePhoneNumberPlaceholder: {
        id: 'piiPhonePhoneNumberPlaceholder',
        defaultMessage: '456789123',
    },
    piiEnterValidPhoneNumber: {
        id: 'piiEnterValidPhoneNumber',
        defaultMessage: 'Please enter valid phone number',
    },
});

type UserProfilePhoneNumberProps = UserProfileModel;

type PhoneNumberModel = {
    area: string;
    mobile: string;
};

const editPermissions = [
    {
        moduleName: ModuleName.PlayerManagement,
        submoduleName: SubmoduleName.RegistrationInformation,
        permissions: [PermissionEnum.Update],
    },
];

export const UserProfilePhoneNumber = (props: UserProfilePhoneNumberProps) => {
    const isEditable =
        useMultiplePermissions({allowedPermissions: editPermissions}) &&
        useRolesPermission(['Super-Admin-PH', 'Super-Admin-Global', 'Super-Admin-Asia']);
    const formatter = useMessageFormatter();

    const defaultModel: UserProfileModel = {
        user: {
            uid: props.userId,
            contact: {
                mobile: {
                    area: null,
                    mobile: null,
                },
            },
        },
        userId: props.userId,
    };
    const {control, state, submit, cancel, handleSubmit} = useReduxForm<Omit<UserProfileModel, 'userId'>, UserProfileModel>({
        initialModel: props.user?.contact?.mobile ? props : defaultModel,
        asyncAction: personalInfoActions.editPhone,
        map: m => ({
            user: {
                uid: props.userId,
                contact: {
                    mobile: m?.user?.contact?.mobile,
                },
            },
            userId: props.userId,
        }),
    });

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Controller
                render={({field, fieldState}) => (
                    <Box>
                        <MultiInputEditableText
                            mode={state.isProgress ? 'InProgress' : 'Read'}
                            inputProps={{
                                area: {
                                    placeholder: formatter(localized.piiPhoneAreaPlaceholder),
                                },
                                mobile: {
                                    placeholder: formatter(localized.piiPhonePhoneNumberPlaceholder),
                                },
                            }}
                            onSave={() => handleSubmit(submit)}
                            onCancel={cancel}
                            value={field.value as PhoneNumberModel}
                            onChange={field.onChange}
                            disabled={!isEditable}
                        ></MultiInputEditableText>
                        {fieldState.error ? <FormError>{fieldState.error.message}</FormError> : <></>}
                    </Box>
                )}
                control={control}
                name="user.contact.mobile"
                defaultValue={props.user?.contact?.mobile}
                rules={{
                    validate: {
                        required: (v: PhoneNumberModel) => (!v?.area || !v?.mobile ? formatter(localized.piiEnterValidPhoneNumber) : null),
                    },
                }}
            ></Controller>
        </form>
    );
};
