import {NormalizedCacheObject} from '@apollo/client';
import {Mapper} from '@automapper/core';
import {Container, interfaces} from 'inversify';
import {UserManagerSettings} from 'oidc-client-ts';

import {CsvDocumentBuilderAdapter} from '@file/services/CsvDocumentBuilderAdapter';
import {FileDownloadService} from '@file/services/FileDownloadService';
import {IEntityFileAdapter, IFileCreator, IFileFactory} from '@file/services/IFileFactory';
import {ServiceTypes} from '@inversify';
import {AgentReportingDownloadModel} from '@models/agent-player-reporting';
import {ITracingService, TracingService} from '@otel';
import {getUserManagerSettings, UserManagerExtended} from '@auth';
import {IEpicsBuilder} from '@redux';
import {AsyncActionsEpicsBuilder} from '@redux/async-action';
import {EntityEpicsBuilder, EntityType, ManagedGameType} from '@redux/entity';
import {RealtimeEpicsBuilder} from '@redux/realtime';
import {RoutingEpicsBuilder} from '@redux/routing';
import {
    AccountVerificationService,
    AgentPlayerReportingService,
    AgentProfileService,
    BoUserService,
    BulkOperationService,
    CountriesService,
    EventLogService,
    GeoCheckSummaryService,
    IEntityReadService,
    LabelGroupService,
    MarketingCodeService,
    OccupationsService,
    PlayersRelationService,
    ReferralSettingsService,
    SportsbookTransactionService,
    StatesService,
    TransactionService,
    UserLoginService,
    UserProfileService,
} from '@services';
import {AgentLevelReportService} from '@services/agentLevelReportService';
import {AgentPathStatisticsService} from '@services/agentPathStatisticsService';
import {AggregatedFinanceSummaryService} from '@services/aggregatedFinanceSummaryService';
import {BackpackService} from '@services/backpackService';
import {BonusCodeService} from '@services/bonusCodeService';
import {BrandService} from '@services/brandService';
import {CasinoGamesService} from '@services/casinoGamesService';
import {CasinoSupplierService} from '@services/casinoSupplierService';
import {EmbeddedReportService} from '@services/embeddedReportService';
import {GameBlindService} from '@services/gameBlindService';
import {GameRoomService} from '@services/gameRoomService';
import {GameTemplateService} from '@services/gameTemplateService';
import {ApolloClientProxy} from '@services/gql-api';
import {ApolloClientGraphQlTransportWsBuilder, IApolloClientBuilder} from '@services/gql-api/apolloClientBuilder';
import {ApolloClientInMemoryCache} from '@services/gql-api/apolloClientInMemoryCache';
import {NoteService} from '@services/noteService';
import {PageViewService} from '@services/pageViewService';
import {PlayerBonusService} from '@services/playerBonusService';
import {PlayerReferralService} from '@services/playerReferralService';
import {PlayerReferrerSummaryService} from '@services/playerReferrerSummaryService';
import {IRealtimeService, RealtimeService} from '@services/RealtimeService';
import {
    ApiRoutesService,
    CountriesApiService,
    InternalUserApiService,
    ISportsbookApiService,
    IUserManagementApiService,
    KeycloakUserManagementApiService,
    OccupationsApiService,
    PaymentApiService,
    PlayerManagementApiService,
    Plo5GameManagementApiService,
    RestFetchApiService,
    RestService,
    RestXhrService,
} from '@services/rest-api';
import {AttachmentsApiService, IAttachmentsApiService} from '@services/rest-api/attachmentsApiService';
import {CasinoCmsApiService, ICasinoCmsApiService} from '@services/rest-api/casinoCmsApiService';
import {IGameManagementApiService} from '@services/rest-api/plo5GameManagementApiService';
import {RuleApiService} from '@services/rest-api/ruleApiService';
import {SportsbookApiService} from '@services/rest-api/sportsbookApiService';
import {RuleService} from '@services/ruleService';
import {UserActivitySummaryService} from '@services/userActivitySummaryService';
import {getJurisdiction} from '@utils';

import {JurisdictionConfigService} from 'src/features/app/config/services';
import {Jurisdiction} from 'src/features/app/config/types';
import {BeBonusService} from 'src/features/be-bonus/services/BeBonusService';
import {AccountVerificationActionsEpicsBuilder} from 'src/features/block-account-verification/epics';
import {KYCBulkActionContainerFacade} from 'src/features/block-account-verification-bulk-actions';
import {PerformKYCAssignStrategy} from 'src/features/block-account-verification-bulk-actions/services/performStrategy';
import {AgentLevelReportEpicsBuilder} from 'src/features/block-agent-level-report/epics';
import {AgentReportingActionsEpicsBuilder, AgentReportingCsvFileFactory} from 'src/features/block-agent-player-reporting-actions';
import {RuleActionsEpicsBuilder} from 'src/features/block-automated-rule/epics';
import {BonusCodeDetailsViewModelKeys, BonusCodeEpicsBuilder} from 'src/features/block-bonus-code';
import {
    ApplyTriggerBonusRequest,
    ApplyTriggerDepositCashMatchBonusStrategy,
    ApplyTriggerImmediateCashBonusStrategy,
    ApplyTriggerMttBonusStrategy,
    BonusCodeDownloadStrategy,
    PerformTriggerDepositCashMatchBonusStrategy,
    PerformTriggerImmediateCashBonusStrategy,
    PerformTriggerMttBonusStrategy,
} from 'src/features/block-bonus-code';
import {
    ActionBulkEpicsBuilder,
    ApplyNoteStrategy,
    IBulkApplyStrategy,
    IBulkStrategy,
    PerformNoteStrategy,
    PerformStrategyOperationResponse,
    PerformStrategyRequest,
    ValidationStrategyResponse,
} from 'src/features/block-bulk-actions';
import {DownloadEpicsBuilder, IDownloadStrategy} from 'src/features/block-download';
import {GameRoomActionsEpicsBuilder} from 'src/features/block-game-room-actions';
import {GameTemplateActionsEpicsBuilder} from 'src/features/block-game-template-actions';
import {BlockUserLabelEpicsBuilder} from 'src/features/block-label-group-actions/epics';
import {
    ApplyAddUserLabelStrategy,
    ApplyRemoveUserLabelStrategy,
    PerformAddUserLabelStrategy,
    PerformRemoveUserLabelStrategy,
} from 'src/features/block-label-group-bulk-actions';
import {NoteActionsEpicsBuilder} from 'src/features/block-note/epics';
import {PageViewEpicsBuilder} from 'src/features/block-page-view';
import {PlayerBonusEpicsBuilder} from 'src/features/block-player-bonus/epics';
import {PlayerReferralEpicsBuilder} from 'src/features/block-player-referral-actions/epics';
import {TransactionActionsEpicsBuilder} from 'src/features/block-transaction-actions';
import {
    ActionBulkTransactionEpicsBuilder,
    ApplyManualTransactionsRequest,
    ApplyManualTransactionsStrategy,
    BulkDebitCreditAddModel,
    LoadManualTransactionsStrategy,
    LoadManualTransactionsStrategyRequest,
    LoadManualTransactionsStrategyResponse,
    ManualTransactionExcelTemplateFactory,
    ParseManualTransactionFileData,
    PerformManualTransactionsStrategy,
    PerformP2PTransfersStrategy,
    ValidationManualTransactionRequest,
    ValidationManualTransactionStrategy,
} from 'src/features/block-transaction-bulk-actions';
import {
    ApplyP2PTransfersRequest,
    ApplyP2PTransfersStrategy,
    ApplyTransactionAssignStrategy,
} from 'src/features/block-transaction-bulk-actions/services/applyStrategy';
import {LoadP2PTransferStrategy} from 'src/features/block-transaction-bulk-actions/services/loadItemsStrategy';
import {PerformTransactionAssignStrategy} from 'src/features/block-transaction-bulk-actions/services/performStrategy';
import {
    ValidationP2PTransferRequest,
    ValidationP2PTransferStrategy,
} from 'src/features/block-transaction-bulk-actions/services/validationStrategy';
import {P2PTransferModel} from 'src/features/block-transaction-bulk-actions/types';
import {UserLoginActionsEpicsBuilder} from 'src/features/block-user-login';
import {UserProfileActionsEpicsBuilder} from 'src/features/block-user-profile-actions/epics';
import {UserAddressInfoEpicsBuilder} from 'src/features/block-user-profile-actions-address/epics';
import {UserProfilePersonalInfoEpicsBuilder} from 'src/features/block-user-profile-actions-pii/epics';
import {
    ApplyAccountLockStrategy,
    ApplyCasinoLockStrategy,
    ApplyDepositLockStrategy,
    ApplyLobbyLockStrategy,
    ApplyP2PLockStrategy,
    ApplySecurityCasesAddStrategy,
    ApplySecurityCasesRemoveStrategy,
    ApplySecurityCasesUpdateStrategy,
    ApplySendMessageStrategy,
    ApplySportsbookLockStrategy,
    ApplyWithdrawalLockStrategy,
    LoadPlayerItemsStrategy,
    PerformAccountLockStrategy,
    PerformCasinoLockStrategy,
    PerformDepositLockStrategy,
    PerformLobbyLockStrategy,
    PerformP2PLockStrategy,
    PerformSecurityCasesStrategy,
    PerformSendMessageStrategy,
    PerformWithdrawalLockStrategy,
} from 'src/features/block-user-profile-bulk-actions';
import {PerformSportsbookLockStrategy} from 'src/features/block-user-profile-bulk-actions';
import {ReferralSettingsEpicsBuilder} from 'src/features/referral-settings/epics';
import type {DynamicConfig} from '../../configuration';
import {ApplyKYCAssignStrategy} from '../../features/block-account-verification-bulk-actions/services/applyStrategy';
import {EmbeddedReportEpicsBuilder} from '../../features/block-embedded-report/epics';
import {initAutoMapper} from '../automapper';

import {ServiceContainer} from './serviceContainer';

export class ContainerBuilder {
    private _inversifyContainer: Container;
    private readonly _config: DynamicConfig;

    constructor(config: DynamicConfig) {
        this._config = config;
    }

    public buildContainer(): Container {
        this._inversifyContainer = new Container({skipBaseClassChecks: true});
        this.buildConfiguration();
        this.configureAuth();
        this.configureApolloClientBuilders();
        this.configureServices();
        this.configureBaseServices();
        this.configureEntityServices();
        this.configureApiServices();
        this.configureFactories();
        this.configureStrategies();
        this.configureEpicBuilders();
        this.configureAutoMapper();
        this.configureServiceContainer();
        this.configureFeatureServices();

        return this._inversifyContainer;
    }

    private configureFeatureServices() {
        const facades = [new KYCBulkActionContainerFacade(this._inversifyContainer)];
        facades.forEach(d => d.bindAll());
    }

    private configureAuth() {
        const {authApiUrl, auth, authClientId, authSilentRefreshPage} = this._config;
        const settings = getUserManagerSettings(auth, authApiUrl, authClientId, authSilentRefreshPage);
        this._inversifyContainer.bind<UserManagerSettings>(ServiceTypes.UserManagerSettings).toConstantValue(settings);
        this._inversifyContainer.bind<UserManagerExtended>(ServiceTypes.UserManager).toConstantValue(new UserManagerExtended(settings));
    }

    private configureApolloClientBuilders() {
        this._inversifyContainer
            .bind<IApolloClientBuilder>(ServiceTypes.ApolloClientBuilderGraphQlWs)
            .to(ApolloClientGraphQlTransportWsBuilder);

        this._inversifyContainer
            .bind<ApolloClientProxy<NormalizedCacheObject>>(ServiceTypes.ApolloClientIGP)
            .toDynamicValue((context: interfaces.Context) => {
                const {igpGraphQlHttpUrl, igpGraphQLWsUrl, igpGraphQlApiKey, igpGraphQlAuthType} = this._config;
                let builder = context.container.get<IApolloClientBuilder>(ServiceTypes.ApolloClientBuilderGraphQlWs);
                builder = builder.withHttp(igpGraphQlHttpUrl).withAuth(igpGraphQlAuthType, {apiKey: igpGraphQlApiKey});

                builder = builder.withWebsocket(igpGraphQLWsUrl);
                return builder.buildClient();
            })
            .inSingletonScope();

        this._inversifyContainer
            .bind<ApolloClientInMemoryCache>(ServiceTypes.ApolloClientInMemoryCache)
            .to(ApolloClientInMemoryCache)
            .inSingletonScope();

        this._inversifyContainer
            .bind<ApolloClientProxy<NormalizedCacheObject>>(ServiceTypes.ApolloClientIGPMocked)
            .toDynamicValue((context: interfaces.Context) => {
                const {igpGraphQlHttpUrl, igpGraphQLWsUrl, igpGraphQlApiKey, igpGraphQlAuthType} = this._config;
                let builder = context.container.get<IApolloClientBuilder>(ServiceTypes.ApolloClientBuilderGraphQlWs);
                const {jurisdiction: defaultJurisdiction} = this._config;
                const jurisdiction = getJurisdiction(location?.search ?? '') ?? defaultJurisdiction;
                const cache =
                    jurisdiction === Jurisdiction.Dev
                        ? context.container.get<ApolloClientInMemoryCache>(ServiceTypes.ApolloClientInMemoryCache).init()
                        : null;

                builder = builder
                    .withHttp(igpGraphQlHttpUrl)
                    .withAuth(igpGraphQlAuthType, {apiKey: igpGraphQlApiKey})
                    .withClientMocking(cache, []);

                builder = builder.withWebsocket(igpGraphQLWsUrl);
                return builder.buildClient();
            })
            .inSingletonScope();

        this._inversifyContainer
            .bind<ApolloClientProxy<NormalizedCacheObject>>(ServiceTypes.ApolloClientPE)
            .toDynamicValue((context: interfaces.Context) => {
                const {peGraphQlApiUrl} = this._config;
                return context.container
                    .get<IApolloClientBuilder>(ServiceTypes.ApolloClientBuilderGraphQlWs)
                    .withHttp(peGraphQlApiUrl)
                    .withOidcAuth()
                    .buildClient();
            })
            .inSingletonScope();
    }

    private configureServices() {
        this._inversifyContainer.bind<BeBonusService>(ServiceTypes.BeBonusService).to(BeBonusService);
        this._inversifyContainer.bind<BrandService>(ServiceTypes.BrandService).to(BrandService);
        this._inversifyContainer.bind<IRealtimeService>(ServiceTypes.RealtimeService).to(RealtimeService);
    }

    private configureBaseServices() {
        this._inversifyContainer.bind<ITracingService>(ServiceTypes.TracingService).to(TracingService);
        this._inversifyContainer.bind<RestService>(ServiceTypes.RestService).to(RestXhrService);
        this._inversifyContainer.bind<RestService>(ServiceTypes.RestFetchApiService).to(RestFetchApiService);
        this._inversifyContainer.bind<ApiRoutesService>(ServiceTypes.ApiRoutesService).to(ApiRoutesService);
        this._inversifyContainer.bind<JurisdictionConfigService>(ServiceTypes.JurisdictionConfigService).to(JurisdictionConfigService);
        this._inversifyContainer.bind<IEntityFileAdapter<string>>(ServiceTypes.CsvFileAdapter).to(CsvDocumentBuilderAdapter);
        this._inversifyContainer.bind<FileDownloadService>(ServiceTypes.FileDownloadService).to(FileDownloadService);
    }

    private configureEntityServices() {
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AccountVerificationService).to(AccountVerificationService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AgentPathStatisticsService).to(AgentPathStatisticsService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.BoUserService).to(BoUserService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.BulkOperationService).to(BulkOperationService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.CountryService).to(CountriesService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.MarketingCodeService).to(MarketingCodeService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.OccupationService).to(OccupationsService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.ReferralSettingsService).to(ReferralSettingsService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.StateService).to(StatesService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.TransactionService).to(TransactionService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.UserProfileService).to(UserProfileService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.LabelGroupService).to(LabelGroupService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.EventLogRecordService).to(EventLogService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.GeoCheckSummaryService).to(GeoCheckSummaryService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.UserLoginService).to(UserLoginService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AggregatedFinanceSummaryService).to(AggregatedFinanceSummaryService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.PlayerReferralService).to(PlayerReferralService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AgentPlayerReportingService).to(AgentPlayerReportingService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AgentProfileService).to(AgentProfileService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.PlayerBonusService).to(PlayerBonusService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.BackpackService).to(BackpackService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.GameTemplateService).to(GameTemplateService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.GameRoomService).to(GameRoomService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.GameBlindService).to(GameBlindService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.PlayerReferrerSummaryService).to(PlayerReferrerSummaryService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.BonusCodeService).to(BonusCodeService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.NoteService).to(NoteService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.RuleService).to(RuleService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.CasinoSupplierService).to(CasinoSupplierService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.CasinoGameService).to(CasinoGamesService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.AgentLevelReportService).to(AgentLevelReportService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.PlayersRelationService).to(PlayersRelationService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.SportsbookTransactionService).to(SportsbookTransactionService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.EmbeddedReportService).to(EmbeddedReportService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.PageViewService).to(PageViewService);
        this._inversifyContainer.bind<IEntityReadService>(ServiceTypes.UserActivitySummaryService).to(UserActivitySummaryService);
        this._inversifyContainer
            .bind<IEntityReadService>(ServiceTypes.EntityReadServiceFactory)
            .toFactory<IEntityReadService>(() => (entityType: EntityType) => {
                const serviceMap: Record<EntityType, IEntityReadService> = {
                    AgentLevelReport: this.getService<IEntityReadService>('AgentLevelReportService'),
                    AccountVerification: this.getService<IEntityReadService>('AccountVerificationService'),
                    AgentPathStatistics: this.getService<IEntityReadService>('AgentPathStatisticsService'),
                    BoModule: this.getService<IEntityReadService>('BoModuleService'),
                    BoRole: this.getService<IEntityReadService>('BoRoleService'),
                    BoUser: this.getService<IEntityReadService>('BoUserService'),
                    BotGroup: this.getService<IEntityReadService>('BotGroupService'),
                    BulkOperation: this.getService<IEntityReadService>('BulkOperationService'),
                    Country: this.getService<IEntityReadService>('CountryService'),
                    FriendGroup: this.getService<IEntityReadService>('FriendGroupService'),
                    KycCheckResults: this.getService<IEntityReadService>('KycCheckResultsService'),
                    LegalDocAcceptanceVersion: this.getService<IEntityReadService>('LegalDocAcceptanceVersionService'),
                    MarketingCode: this.getService<IEntityReadService>('MarketingCodeService'),
                    Note: this.getService<IEntityReadService>('NoteService'),
                    Occupation: this.getService<IEntityReadService>('OccupationService'),
                    PlayerBonus: this.getService<IEntityReadService>('PlayerBonusService'),
                    PlayerReferral: this.getService<IEntityReadService>('PlayerReferralService'),
                    PlayerReferrerSummary: this.getService<IEntityReadService>('PlayerReferrerSummaryService'),
                    PlayersRelation: this.getService<IEntityReadService>('PlayersRelationService'),
                    ReferralSettings: this.getService<IEntityReadService>('ReferralSettingsService'),
                    RgUserLimits: this.getService<IEntityReadService>('RgUserLimitsService'),
                    SecurityCase: this.getService<IEntityReadService>('SecurityCaseService'),
                    State: this.getService<IEntityReadService>('StateService'),
                    Transaction: this.getService<IEntityReadService>('TransactionService'),
                    UserAction: this.getService<IEntityReadService>('UserActionService'),
                    UserLogin: this.getService<IEntityReadService>('UserLoginService'),
                    UserProfile: this.getService<IEntityReadService>('UserProfileService'),
                    LabelGroup: this.getService<IEntityReadService>('LabelGroupService'),
                    UserStatistics: this.getService<IEntityReadService>('UserStatisticsService'),
                    EventLogRecord: this.getService<IEntityReadService>('EventLogRecordService'),
                    GeoCheckSummary: this.getService<IEntityReadService>('GeoCheckSummaryService'),
                    AggregatedFinanceSummary: this.getService<IEntityReadService>('AggregatedFinanceSummaryService'),
                    AgentPlayerReporting: this.getService<IEntityReadService>('AgentPlayerReportingService'),
                    AgentProfile: this.getService<IEntityReadService>('AgentProfileService'),
                    Backpack: this.getService<IEntityReadService>('BackpackService'),
                    GameRoom: this.getService<IEntityReadService>('GameRoomService'),
                    GameTemplate: this.getService<IEntityReadService>('GameTemplateService'),
                    GameBlind: this.getService<IEntityReadService>('GameBlindService'),
                    BonusCode: this.getService<IEntityReadService>('BonusCodeService'),
                    Rule: this.getService<IEntityReadService>('RuleService'),
                    CasinoGame: this.getService<IEntityReadService>('CasinoGameService'),
                    CasinoSupplier: this.getService<IEntityReadService>('CasinoSupplierService'),
                    SportsbookTransaction: this.getService<IEntityReadService>('SportsbookTransactionService'),
                    EmbeddedReport: this.getService<IEntityReadService>('EmbeddedReportService'),
                    PageView: this.getService<IEntityReadService>('PageViewService'),
                    UserActivitySummary: this.getService<IEntityReadService>('UserActivitySummaryService'),
                };
                return serviceMap[entityType];
            });
    }

    private getService<IService>(serviceKey: keyof typeof ServiceTypes) {
        return this._inversifyContainer.isBound(ServiceTypes[serviceKey])
            ? this._inversifyContainer.get<IService>(ServiceTypes[serviceKey])
            : null;
    }

    private configureApiServices() {
        this._inversifyContainer
            .bind<IUserManagementApiService>(ServiceTypes.UserManagementApiService)
            .to(this._config.auth === 'internal' ? InternalUserApiService : KeycloakUserManagementApiService);
        this._inversifyContainer.bind<InternalUserApiService>(ServiceTypes.BoUserApiService).to(InternalUserApiService);
        this._inversifyContainer.bind<CountriesApiService>(ServiceTypes.CountriesApiService).to(CountriesApiService);
        this._inversifyContainer.bind<OccupationsApiService>(ServiceTypes.OccupationsApiService).to(OccupationsApiService);
        this._inversifyContainer.bind<PaymentApiService>(ServiceTypes.PaymentApiService).to(PaymentApiService);
        this._inversifyContainer.bind<PlayerManagementApiService>(ServiceTypes.PlayerManagementApiService).to(PlayerManagementApiService);
        this._inversifyContainer
            .bind<IGameManagementApiService>(ServiceTypes.Plo5GameManagementApiService)
            .to(Plo5GameManagementApiService);

        this._inversifyContainer
            .bind<IGameManagementApiService>(ServiceTypes.GameManagementApiServiceFactory)
            .toFactory(() => (gameType: ManagedGameType): IGameManagementApiService => {
                const serviceMap: Record<ManagedGameType, IGameManagementApiService> = {
                    [ManagedGameType.PLO5]: this.getService<IGameManagementApiService>('Plo5GameManagementApiService'),
                };
                return serviceMap[gameType];
            });
        this._inversifyContainer.bind<RuleApiService>(ServiceTypes.RuleApiService).to(RuleApiService);
        this._inversifyContainer.bind<ICasinoCmsApiService>(ServiceTypes.CasinoCmsApiService).to(CasinoCmsApiService);
        this._inversifyContainer.bind<IAttachmentsApiService>(ServiceTypes.AttachmentsApiService).to(AttachmentsApiService);
        this._inversifyContainer.bind<ISportsbookApiService>(ServiceTypes.SportsbookApiService).to(SportsbookApiService);
    }

    private configureFactories() {
        this._inversifyContainer
            .bind<IFileFactory<ParseManualTransactionFileData, BulkDebitCreditAddModel[]>>(
                ServiceTypes.ManualTransactionExcelTemplateFactory
            )
            .to(ManualTransactionExcelTemplateFactory);
        this._inversifyContainer
            .bind<IFileCreator<AgentReportingDownloadModel>>(ServiceTypes.AgentReportingCsvFileFactory)
            .to(AgentReportingCsvFileFactory);
    }

    private configureStrategies() {
        this._inversifyContainer
            .bind<IBulkStrategy<LoadManualTransactionsStrategyRequest, LoadManualTransactionsStrategyResponse>>(
                ServiceTypes.LoadManualTransactionsStrategy
            )
            .to(LoadManualTransactionsStrategy);
        this._inversifyContainer.bind<LoadPlayerItemsStrategy>(ServiceTypes.LoadPlayerItemsStrategy).to(LoadPlayerItemsStrategy);
        this._inversifyContainer.bind<LoadP2PTransferStrategy>(ServiceTypes.LoadP2PTransferStrategy).to(LoadP2PTransferStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<ValidationManualTransactionRequest, ValidationStrategyResponse>>(
                ServiceTypes.ValidationManualTransactionsStrategy
            )
            .to(ValidationManualTransactionStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<ValidationP2PTransferRequest, ValidationStrategyResponse>>(ServiceTypes.ValidationP2PTransferStrategy)
            .to(ValidationP2PTransferStrategy);
        this._inversifyContainer
            .bind<IBulkApplyStrategy<ApplyManualTransactionsRequest>>(ServiceTypes.ApplyManualTransactionsStrategy)
            .to(ApplyManualTransactionsStrategy);
        this._inversifyContainer
            .bind<IBulkApplyStrategy<ApplyP2PTransfersRequest>>(ServiceTypes.ApplyP2PTransfersStrategy)
            .to(ApplyP2PTransfersStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(ServiceTypes.PerformManualTransactionsStrategy)
            .to(PerformManualTransactionsStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<PerformStrategyRequest<P2PTransferModel>, PerformStrategyOperationResponse>>(
                ServiceTypes.PerformP2PTransfersStrategy
            )
            .to(PerformP2PTransfersStrategy);
        this._inversifyContainer
            .bind<IBulkApplyStrategy<ApplyTriggerBonusRequest>>(ServiceTypes.ApplyTriggerMttBonusStrategy)
            .to(ApplyTriggerMttBonusStrategy);
        this._inversifyContainer
            .bind<IBulkApplyStrategy<ApplyTriggerBonusRequest>>(ServiceTypes.ApplyTriggerImmediateCashBonusStrategy)
            .to(ApplyTriggerImmediateCashBonusStrategy);
        this._inversifyContainer
            .bind<IBulkApplyStrategy<ApplyTriggerBonusRequest>>(ServiceTypes.ApplyTriggerDepositCashMatchBonusStrategy)
            .to(ApplyTriggerDepositCashMatchBonusStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(ServiceTypes.PerformTriggerMttBonusStrategy)
            .to(PerformTriggerMttBonusStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(
                ServiceTypes.PerformTriggerImmediateCashBonusStrategy
            )
            .to(PerformTriggerImmediateCashBonusStrategy);
        this._inversifyContainer
            .bind<IBulkStrategy<PerformStrategyRequest, PerformStrategyOperationResponse>>(
                ServiceTypes.PerformTriggerDepositCashMatchBonusStrategy
            )
            .to(PerformTriggerDepositCashMatchBonusStrategy);
        this._inversifyContainer.bind<PerformP2PLockStrategy>(ServiceTypes.PerformP2PLockStrategy).to(PerformP2PLockStrategy);
        this._inversifyContainer
            .bind<ApplySecurityCasesAddStrategy>(ServiceTypes.ApplySecurityCasesAddStrategy)
            .to(ApplySecurityCasesAddStrategy);
        this._inversifyContainer
            .bind<ApplySecurityCasesUpdateStrategy>(ServiceTypes.ApplySecurityCasesUpdateStrategy)
            .to(ApplySecurityCasesUpdateStrategy);
        this._inversifyContainer
            .bind<ApplySecurityCasesRemoveStrategy>(ServiceTypes.ApplySecurityCasesRemoveStrategy)
            .to(ApplySecurityCasesRemoveStrategy);
        this._inversifyContainer
            .bind<PerformSecurityCasesStrategy>(ServiceTypes.PerformSecurityCasesStrategy)
            .to(PerformSecurityCasesStrategy);
        this._inversifyContainer.bind<ApplySendMessageStrategy>(ServiceTypes.ApplySendMessageStrategy).to(ApplySendMessageStrategy);
        this._inversifyContainer.bind<ApplyNoteStrategy>(ServiceTypes.ApplyNoteStrategy).to(ApplyNoteStrategy);
        this._inversifyContainer.bind<PerformSendMessageStrategy>(ServiceTypes.PerformSendMessageStrategy).to(PerformSendMessageStrategy);
        this._inversifyContainer.bind<PerformNoteStrategy>(ServiceTypes.PerformNoteStrategy).to(PerformNoteStrategy);
        this._inversifyContainer.bind<ApplyAddUserLabelStrategy>(ServiceTypes.ApplyAddUserLabelStrategy).to(ApplyAddUserLabelStrategy);
        this._inversifyContainer
            .bind<ApplyRemoveUserLabelStrategy>(ServiceTypes.ApplyRemoveUserLabelStrategy)
            .to(ApplyRemoveUserLabelStrategy);
        this._inversifyContainer
            .bind<PerformAddUserLabelStrategy>(ServiceTypes.PerformAddUserLabelStrategy)
            .to(PerformAddUserLabelStrategy);
        this._inversifyContainer
            .bind<PerformRemoveUserLabelStrategy>(ServiceTypes.PerformRemoveUserLabelStrategy)
            .to(PerformRemoveUserLabelStrategy);
        this._inversifyContainer.bind<ApplyAccountLockStrategy>(ServiceTypes.ApplyAccountLockStrategy).to(ApplyAccountLockStrategy);
        this._inversifyContainer
            .bind<ApplyWithdrawalLockStrategy>(ServiceTypes.ApplyWithdrawalLockStrategy)
            .to(ApplyWithdrawalLockStrategy);
        this._inversifyContainer.bind<ApplyDepositLockStrategy>(ServiceTypes.ApplyDepositLockStrategy).to(ApplyDepositLockStrategy);
        this._inversifyContainer.bind<ApplyLobbyLockStrategy>(ServiceTypes.ApplyLobbyLockStrategy).to(ApplyLobbyLockStrategy);
        this._inversifyContainer.bind<ApplyCasinoLockStrategy>(ServiceTypes.ApplyCasinoLockStrategy).to(ApplyCasinoLockStrategy);
        this._inversifyContainer
            .bind<ApplySportsbookLockStrategy>(ServiceTypes.ApplySportsbookLockStrategy)
            .to(ApplySportsbookLockStrategy);
        this._inversifyContainer.bind<ApplyP2PLockStrategy>(ServiceTypes.ApplyP2PLockStrategy).to(ApplyP2PLockStrategy);
        this._inversifyContainer
            .bind<PerformWithdrawalLockStrategy>(ServiceTypes.PerformWithdrawalLockStrategy)
            .to(PerformWithdrawalLockStrategy);
        this._inversifyContainer.bind<PerformDepositLockStrategy>(ServiceTypes.PerformDepositLockStrategy).to(PerformDepositLockStrategy);
        this._inversifyContainer.bind<PerformLobbyLockStrategy>(ServiceTypes.PerformLobbyLockStrategy).to(PerformLobbyLockStrategy);
        this._inversifyContainer.bind<PerformAccountLockStrategy>(ServiceTypes.PerformAccountLockStrategy).to(PerformAccountLockStrategy);
        this._inversifyContainer.bind<PerformCasinoLockStrategy>(ServiceTypes.PerformCasinoLockStrategy).to(PerformCasinoLockStrategy);
        this._inversifyContainer
            .bind<PerformSportsbookLockStrategy>(ServiceTypes.PerformSportsbookLockStrategy)
            .to(PerformSportsbookLockStrategy);

        this._inversifyContainer.bind<ApplyKYCAssignStrategy>(ServiceTypes.ApplyKYCAssignStrategy).to(ApplyKYCAssignStrategy);
        this._inversifyContainer
            .bind<ApplyTransactionAssignStrategy>(ServiceTypes.ApplyTransactionAssignStrategy)
            .to(ApplyTransactionAssignStrategy);
        this._inversifyContainer.bind<PerformKYCAssignStrategy>(ServiceTypes.PerformKYCAssignStrategy).to(PerformKYCAssignStrategy);
        this._inversifyContainer
            .bind<PerformTransactionAssignStrategy>(ServiceTypes.PerformTransactionAssignStrategy)
            .to(PerformTransactionAssignStrategy);

        // Download strategies
        this._inversifyContainer
            .bind<IDownloadStrategy<BonusCodeDetailsViewModelKeys>>(ServiceTypes.BonusCodeDownloadStrategy)
            .to(BonusCodeDownloadStrategy);
    }

    private configureEpicBuilders() {
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.ActionBulkTransactionEpicsBuilder).to(ActionBulkTransactionEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.ActionBulkEpicsBuilder).to(ActionBulkEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.EntityEpicsBuilder).to(EntityEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.AsyncActionsEpicsBuilder).to(AsyncActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.RoutingEpicsBuilder).to(RoutingEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.RealtimeEpicsBuilder).to(RealtimeEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.BlockUserLabelEpicsBuilder).to(BlockUserLabelEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.ReferralSettingsEpicsBuilder).to(ReferralSettingsEpicsBuilder);
        this._inversifyContainer
            .bind<IEpicsBuilder>(ServiceTypes.AccountVerificationActionsEpicsBuilder)
            .to(AccountVerificationActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.TransactionActionsEpicsBuilder).to(TransactionActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.UserProfileActionsEpicsBuilder).to(UserProfileActionsEpicsBuilder);
        this._inversifyContainer
            .bind<IEpicsBuilder>(ServiceTypes.UserProfilePersonalInfoEpicsBuilder)
            .to(UserProfilePersonalInfoEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.UserAddressInfoEpicsBuilder).to(UserAddressInfoEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.UserLoginActionsEpicsBuilder).to(UserLoginActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.PlayerReferralEpicsBuilder).to(PlayerReferralEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.AgentReportingActionsEpicsBuilder).to(AgentReportingActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.GameTemplateActionsEpicsBuilder).to(GameTemplateActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.GameRoomActionsEpicsBuilder).to(GameRoomActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.BonusCodeEpicsBuilder).to(BonusCodeEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.NoteActionsEpicsBuilder).to(NoteActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.RuleActionsEpicsBuilder).to(RuleActionsEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.PlayerBonusEpicsBuilder).to(PlayerBonusEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.AgentLevelReportEpicsBuilder).to(AgentLevelReportEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.EmbeddedReportEpicsBuilder).to(EmbeddedReportEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.PageViewEpicsBuilder).to(PageViewEpicsBuilder);
        this._inversifyContainer.bind<IEpicsBuilder>(ServiceTypes.DownloadEpicsBuilder).to(DownloadEpicsBuilder);
    }

    private configureAutoMapper() {
        const mapper = initAutoMapper();
        this._inversifyContainer.bind<Mapper>(ServiceTypes.AutoMapper).toConstantValue(mapper);
    }

    private buildConfiguration() {
        this._inversifyContainer.bind(ServiceTypes.Config).toConstantValue(this._config);
    }

    /**
     * @deprecated
     * <p>Temporary configuration for old services</p>
     */
    private configureServiceContainer() {
        this._inversifyContainer.bind<ServiceContainer>(ServiceTypes.ServiceContainer).to(ServiceContainer);
    }
}
