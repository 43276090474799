import {defineMessages, MessageDescriptor} from 'react-intl';

import {SubmoduleName} from '@models/modules';

export const localizedSubmoduleName = defineMessages<SubmoduleName, MessageDescriptor>({
    [SubmoduleName.AccountActions]: {
        id: 'submoduleName_AccountActions',
        defaultMessage: 'Account Actions',
    },
    [SubmoduleName.ActionCenter]: {
        id: 'submoduleName_ActionCenter',
        defaultMessage: 'Action Center',
    },
    [SubmoduleName.BlacklistActions]: {
        id: 'submoduleName_BlacklistActions',
        defaultMessage: 'Blacklist Actions',
    },
    [SubmoduleName.BonusEngine]: {
        id: 'submoduleName_BonusEngine',
        defaultMessage: 'Bonus Engine',
    },
    [SubmoduleName.Cases]: {
        id: 'submoduleName_Cases',
        defaultMessage: 'Cases',
    },
    [SubmoduleName.CommunicationActions]: {
        id: 'submoduleName_CommunicationActions',
        defaultMessage: 'Communication Actions',
    },
    [SubmoduleName.Details]: {
        id: 'submoduleName_Details',
        defaultMessage: 'Details',
    },
    [SubmoduleName.FinanceActions]: {
        id: 'submoduleName_FinanceActions',
        defaultMessage: 'Finance Actions',
    },
    [SubmoduleName.P2PAction]: {
        id: 'submoduleName_P2PAction',
        defaultMessage: 'P2P Action',
    },
    [SubmoduleName.GGRReport]: {
        id: 'submoduleName_PlayerLevelReport',
        defaultMessage: 'Player Level Report',
    },
    [SubmoduleName.GamesActions]: {
        id: 'submoduleName_GamesActions',
        defaultMessage: 'Games Actions',
    },
    [SubmoduleName.LoginHistory]: {
        id: 'submoduleName_LoginHistory',
        defaultMessage: 'Login History',
    },
    [SubmoduleName.Marketing]: {
        id: 'submoduleName_marketing',
        defaultMessage: 'Marketing',
    },
    [SubmoduleName.MarketingBonusCode]: {
        id: 'submoduleName_MarketingBonusCode',
        defaultMessage: 'Marketing Bonus Codes',
    },
    [SubmoduleName.None]: {
        id: 'submoduleName_None',
        defaultMessage: 'None',
    },
    [SubmoduleName.NotesBotDetection]: {
        id: 'submoduleName_BotDetection',
        defaultMessage: 'Bot Detection',
    },
    [SubmoduleName.NotesCustomerSupport]: {
        id: 'submoduleName_CustomerSupport',
        defaultMessage: 'Customer Support',
    },
    [SubmoduleName.KYC]: {
        id: 'submoduleName_KYC',
        defaultMessage: 'KYC',
    },
    [SubmoduleName.Payment]: {
        id: 'submoduleName_Payment',
        defaultMessage: 'Payment',
    },
    [SubmoduleName.NotesPlayer]: {
        id: 'submoduleName_PlayerDetails',
        defaultMessage: 'Player Details',
    },
    [SubmoduleName.NotesSecurity]: {
        id: 'submoduleName_Security',
        defaultMessage: 'Security',
    },
    [SubmoduleName.P2PReferralOfferingAdmin]: {
        id: 'submoduleName_P2PReferralOfferingAdmin',
        defaultMessage: 'P2P Referral Offering Admin',
    },
    [SubmoduleName.P2PTransfer]: {
        id: 'submoduleName_P2PTransfer',
        defaultMessage: 'P2P Transfer',
    },
    [SubmoduleName.PLO5]: {
        id: 'submoduleName_plo5',
        defaultMessage: 'PLO5',
    },
    [SubmoduleName.PermanentBans]: {
        id: 'submoduleName_PermanentBans',
        defaultMessage: 'Permanent Bans',
    },
    [SubmoduleName.PrivacyPolicy]: {
        id: 'submoduleName_PrivacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    [SubmoduleName.RegistrationInformation]: {
        id: 'submoduleName_RegistrationInformation',
        defaultMessage: 'Registration Information',
    },
    [SubmoduleName.RevenueShare]: {
        id: 'submoduleName_RevenueShare',
        defaultMessage: 'Revenue Share',
    },
    [SubmoduleName.Roles]: {
        id: 'submoduleName_Roles',
        defaultMessage: 'Roles',
    },
    [SubmoduleName.TermsAndConditions]: {
        id: 'submoduleName_TermsAndConditions',
        defaultMessage: 'Terms & Conditions',
    },
    [SubmoduleName.TransactionHistory]: {
        id: 'submoduleName_TransactionHistory',
        defaultMessage: 'Transaction History',
    },
    [SubmoduleName.TransferReport]: {
        id: 'submoduleName_TransferReport',
        defaultMessage: 'P2P Transfer',
    },
    [SubmoduleName.UserAcceptanceVersion]: {
        id: 'submoduleName_UserAcceptanceVersion',
        defaultMessage: 'User Acceptance Version',
    },
    [SubmoduleName.Users]: {
        id: 'submoduleName_Users',
        defaultMessage: 'Users',
    },
    [SubmoduleName.Verification]: {
        id: 'submoduleName_Verification',
        defaultMessage: 'Verification',
    },
    [SubmoduleName.WithdrawalProcessingRisk]: {
        id: 'submoduleName_Risk',
        defaultMessage: 'Risk',
    },
    [SubmoduleName.AgentLevelReport]: {
        id: 'submoduleName_AgentLevelReport',
        defaultMessage: 'Agent Level Report',
    },
    [SubmoduleName.SportsbookTransactionHistory]: {
        id: 'submoduleName_SportsbookTransactionHistory',
        defaultMessage: 'Sportsbook Transaction History',
    },
    [SubmoduleName.FinanceLock]: {
        id: 'submoduleName_FinanceLock',
        defaultMessage: 'WD/Dep Locks',
    },
    [SubmoduleName.P2PLock]: {
        id: 'submoduleName_P2PLock',
        defaultMessage: 'P2P Lock',
    },
    [SubmoduleName.P2PTransaction]: {
        id: 'submoduleName_P2PTransaction',
        defaultMessage: 'P2P Transfer',
    },
    [SubmoduleName.BatchTransaction]: {
        id: 'submoduleName_BatchTransaction',
        defaultMessage: 'Batch Transactions (Debit and Credit)',
    },
    [SubmoduleName.ManualTransaction]: {
        id: 'submoduleName_ManualTransaction',
        defaultMessage: 'Manual Transactions (Debit and Credit)',
    },
    [SubmoduleName.NotesFraud]: {
        id: 'submoduleName_NotesFraud',
        defaultMessage: 'Fraud',
    },
});
